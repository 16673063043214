import Glide from '@glidejs/glide';

let glideFeatured = new Glide('.glide-featured-resources', {
  type: 'carousel',
  perView: 5,
  perTouch: 5,
  // focusAt: 'center',
  startAt: 1,
  animationDuration: 1800,
  autoplay: 8000,
  hoverpause: true,
  gap: 0,
});
glideFeatured.mount();

